// @flow
const SPANISH: TranslateStringsType = {
  forTennessee: 'Para Tennessee',
  immigrantParents: 'Padres Inmigrantes',
  ensureCare:
    'Asegurar el cuidado de un menor en el caso de deportación o detención mediante el otorgamiento de un poder notarial.',
  immigrant:
    'Parientes imigrantes que enfrentan detenimiento o deportación con pequeño o ningun aviso. En muchos casos, ambos Parientes estan en la misma localizacion cuando son detenidos o enfrentan deportación. Como resultado, menor(es) de los Parientes detenidos y/o deportados se quedan sin ningun guardian legal. Una vez detenidos y deportados, los parientes se quedan sin acceso por dias o semanas con poco o ningun contacto con su hijo(a) o familia. Parientes enfrentando detenimiento o deportacion pueden preparar, en avanzado, para el cuido de su menor al otorgar el Poder Notarial. El Poder Notarial permite a Parientes imigrantes otorgar permiso a un adulto de confianza el cuido de su hijo menor en el evento de detencion o deportacion.',
  gettingStarted:
    "Comenzar la forma 'en linea' de Poder Notairal es rápido y fácil ",
  startForm: 'Comenzar Forma',
  childsFullName: 'Nombre Completo Del Menor',
  pleaseAddStreet: 'Favor de añadir dirección.',
  name: 'Nombre',
  pleaseAddName: 'Ingresar Nombre.',
  streetAddress: 'Dirección',
  pleaseAddCity: 'Escojer una ciudad.',
  pleaseAddState: 'Escojer un estado.',
  city: 'Ciudad',
  state: 'Estado',
  pleaseAddZip: 'Favor de ingresar su codigo postal.',
  zip: 'Codigo Postal',
  numberOfChildren: 'Cantidad de menores',
  minorName: 'Nombre de el menor',
  pleaseAddChildName: 'Favor de añadir nombre de cada menor.',
  motherName: 'Madre/Guardian Legal, Nombre & Dirección',
  fatherName: 'Padre/Guardian Legal, Nombre & Dirección',
  caregiverName: 'Nombre & Direccion del Cuidador',
  parentalStatus: 'Estado de los Padres',
  pleaseAddParentalStatus: 'Porfavor añadir estado de los Padres.',
  pleaseAddReason: 'Añadir una razón.',
  bothParents:
    'Ambos parientes viven, tienen custodia legal de el menor y han firmado este documento',
  parentDeceased: 'Un pariente ha fallecido',
  legalCustodySigned:
    'Un Pariente tiene custodia legal de el menor y ambos Parientes han firmado este documento y citan su consentimiento al Guardian',
  legalCustodySent:
    'Un Pariente tiene custodia legal de el menor, y ha enviado por Correo Certificado, Recibo de Retorno pedido, hacia el otro Pariente a la ultima dirección conocida, una copia de este documento y un aviso en las provisiones en § 34-6-305;',
  legalCustodySentLink:
    'Haz clic aqui para una copia del texto de T.C.A. § 34-6-305 (2017)',
  legalCustodyNoConsent:
    'El pariente sin custodia no a dado el consentimiento a la designacion o su consentimiento no puede ser obtenido.',
  reasonNotReached:
    'Razon la cual el Pariente sin custodia no puede ser alcanzado:',
  powerOfAttorney: 'PODER NOTARIAL PARA EL CUIDO DE UN MENOR',
  childInformation: 'Información de el Menor',
  guardianInformation: 'Información de el Guardian',
  caregiversInformation: 'Informacion de el Cuidador',
  parentalstatus: 'Estado de Parientes',
  partI: 'Parte I:',
  submit: 'Someter',
  next: 'siguiente',
  formWithErrors: 'La forma tiene {{errorCount}} error(es)',
  faq: 'Preguntas Más Frequentes',
  whatIsPOA: '¿Que es un Poder Notarial?',
  poaIs:
    'El Poder Notarial es un documento legal en el cual un Pariente autoriza a otro adulto el cuido de su menor. Al firmar el Poder Notarial, el pariente no entrega su derecho como pariente.',
  WhatIsThisFormUsedFor: '¿Para que es usada esta forma?',
  formWasCreated:
    'Esta forma fue creada para parientes en Tennessee que enfrentan una amenaza de ser detenidos y/o deportados. Esta permite a parientes a encomendar a un cuidador temporero, derechos para hacer desiciones por el menor de su parte. El Poder Notarial entra en effecto solo solo si los Parientes han sido detenidos o deportados. Al completar esta forma, junto con las debidas firmas, autoriza al cuidador a inscribir a el menor en una escuela, obtener trato medico para el menor, y proveer comida para el menor, hogar, y viaje.',
  whoShouldIChoose: '¿A quién debo escoger como cuidador?',
  yourCaregiver:
    'Su cuidador debe ser alguien en quien confia. Este documento le permite a el cuidador el derecho y responsabilidad de tomar desiciones por su menor de su parte. La persona a la que ha designado para el cuido de su menor debe ser ciudadano EE.UU. o alquien con un estado migratorio no incluya a alguien que tambien enfrenta amenaza de detenimiento o deportacion.',
  howCanITerminate: '¿Como yo puedo terminar el Poder Notarial?',
  youCanTerminate:
    'Usted puede terminar el Poder Notarial en cualquier momento al ponerlo en escrito.',
  whereCanILearn:
    '¿Donde puedo aprender mas sobre Poder Notarial para el cuido de un menor?',
  lawCanBeFound:
    'Una copia del Codigo Tennessee (T.C.A. 2017) puede ser encontrada',
  here: 'aqui',
  tnPOA: 'Ley de Poder Notarial en Tennessee para un menor',
  Home: 'Hogar',
  FAQ: 'FAQ',
  beforeStart: 'Antes de comenzar...',
  useOfThisForm:
    'Esta forma es información, no consejo legal. Aunque esta forma no cancela sus derechos como Pariente, la forma provee a un guardian la autoridad para el cuido de su menor. Si no está seguro(a) de sus derechos o necesidad, debería consultar con un abogado para discutir sus necesidades legales. Este sitio web no rastrea, guarda o retiene ninguna informacion. Si usted sale de este sitio web sin descargar la forma, tendrí que comenzar desde el principio. El uso de esta froma es autorizado por T.C.A. § 34-6-301 et seq. Al completar esta forma, con las firmas apropiadas, es sufficiente para autorizar inscripción de un menor a una escuela y autorizar trato médico. Aunque, una escuela del distrito escolar pueda requerir documentación/información adicional puede mientras permitida por esta sección de la ley de Tennessee antes de inscribir a un menor en la escuela o cualquier actividad extracurricular.',
  thisFormIsToBeFilled:
    'Esta forma es para ser iniciada y/o completada por pariente(es)/guardian legal).',
  MORE_INFORMATION: 'MAS INFORMACIÓN',
  I_UNDERSTAND: 'YO ENTIENDO',
  Form: 'Forma',
  Open: 'Abrir',
  Download: 'Descargar',
  unknownError:
    'Un error desconocido a ocurrido - porfavor refresque la pagina e intente otra vez.',
  cuteBabyBoy: 'Lindo bebé mirando hacia arriba.',
  back: 'Atrás',
  doesNotTrack:
    'Su información y privacidad es importante para nosotros.  Esta pagina web no rastrea, mantiene o monitorea la información ingresada.',
  formWasCreatedNote:
    'NOTA: Cada menor debe tener una forma de Poder Notarial por separado.  Usted no puede usar solo una forma para todos los menores.',
  howMuch: 'Cuanto cuesta un Poder Notarial?',
  howMuchAnswer:
    'Es libre de cargo.  Puede haber un pequeno cargo debido al notario publico por noratizar el documento.',
  privacy: 'Privacidad y Confidencialidad',
  whatDocuments: '¿Qué documentos necesito?',
  whatDocumentsAnswer:
    'ID de prueba de Parientes y prueba de ID de el cuidador asignado sera requerido para notarizar el documento.  El documento debe ser firmado por todos los participantes frente a un notario o dos testigos.  Un documento notarizado es usualmente mas effectivo, y es altamente sugerido que notarize el documento mejor que simplemente buscar dos testigos.',
  whereSendDocuments: 'Donde necesito enviar los documentos?',
  whereSendDocumentsAnswer:
    'Usted deberia proveer una copia de el Poder Notarial y el Certificado de Nacimiento de el menor al Cuidador.  Usted tambien podria proveer una copia de el Poder Notarial a ambos, la escuela y los doctores de el menor.'
}

export default SPANISH
